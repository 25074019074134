import { ContainerProps, Container } from '@chakra-ui/layout';
import React from 'react';

export interface DecoratedContainerProps extends ContainerProps {}

const DecoratedContainer: React.FC<DecoratedContainerProps> = (props) => {
  return <Container px={{ xs: '2rem', md: '5rem' }} maxW="8xl" {...props} />;
};

export default DecoratedContainer;
