import { Stars } from '@react-three/drei';
import { Canvas, useFrame } from '@react-three/fiber';
import React, { useRef } from 'react';
import * as THREE from 'three';

function Box(props: JSX.IntrinsicElements['mesh']) {
  const mesh = useRef<THREE.Mesh>(null!);
  useFrame(() => (mesh.current.rotation.x += 0.0001));

  return (
    <mesh ref={mesh} {...props}>
      <ambientLight />
      <Stars count={1000} />
    </mesh>
  );
}

const StarsBackground = () => {
  return (
    <Canvas
      style={{
        zIndex: 0,
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
      }}>
      <Box position={[-1.2, 0, 0]} />
    </Canvas>
  );
};

export default StarsBackground;
