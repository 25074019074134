import {
  Stack,
  Box,
  Text,
  Button,
  Image,
  StackProps,
  IconButton,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
} from '@chakra-ui/react';
import Container from '_components/DecoratedContainer';
import UserNavbarIndicator from '_components/UserNavbarIndicator';
import _debounce from 'lodash/debounce';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import { IoReorderThreeOutline, IoCloseOutline } from 'react-icons/io5';

type Menu = {
  title: string;
  path: string;
  type?: 'text' | 'button';
};

export interface HomeNavProps extends StackProps {
  menus: Menu[];
  colorizedLogo?: boolean;
  detailedLogo?: boolean;
  // disable scroll activation
  static?: boolean;
}

const isActive = (actualPath: string, path: string) => {
  if (actualPath == path) return true;

  return actualPath.length > 1 && path.includes(actualPath);
};

const HomeNav: React.FC<HomeNavProps> = (props) => {
  const router = useRouter();
  const [isScrolled, setIsScrolled] = React.useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const scrollHandler = React.useCallback(
    _debounce(() => {
      if (!props.static && window.scrollY >= 30) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    }, 100),
    [props.static],
  );

  React.useEffect(() => {
    window.addEventListener('scroll', scrollHandler);

    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  }, []);

  const subMenu = React.useMemo(
    () =>
      props.menus.map((menu) => (
        <Box key={menu.title + menu.path}>
          {menu.type === 'button' ? (
            <Link href={menu.path}>
              <Button
                pointerEvents="all"
                clipPath="polygon(10% 0%, 90% 0%, 100% 50%, 90% 100%, 10% 100%, 0% 50%)"
                textTransform="uppercase"
                cursor="pointer"
                colorScheme="bluesky"
                px="1.5rem"
                as="a">
                {menu.title}
              </Button>
            </Link>
          ) : (
            <Link href={menu.path}>
              <Box as="a" pointerEvents="all">
                <Text
                  color={isActive(menu.path, router.pathname) && 'bluesky.200'}
                  fontWeight="500"
                  textTransform="uppercase"
                  cursor="pointer"
                  borderLeftColor={{
                    xs: isActive(menu.path, router.pathname) && 'bluesky.200',
                    xl: 'transparent',
                  }}
                  borderLeftWidth={{
                    xs: isActive(menu.path, router.pathname) && '.2rem',
                    xl: 0,
                  }}
                  pl={{
                    xs: isActive(menu.path, router.pathname) && '.5rem',
                    xl: 0,
                  }}
                  borderStyle="solid"
                  _hover={{
                    color: 'bluesky.400',
                  }}>
                  {menu.title}
                </Text>
                {isActive(menu.path, router.pathname) && (
                  <Box
                    display={{ xs: 'none', xl: 'block' }}
                    as="span"
                    h=".1rem"
                    w="50%"
                    bg="bluesky.500"
                  />
                )}
              </Box>
            </Link>
          )}
        </Box>
      )),
    [props.menus],
  );

  return (
    <Box
      pointerEvents="none"
      position="fixed"
      zIndex="100"
      top="0"
      left="0"
      w="100%"
      filter={isScrolled && 'drop-shadow(2px 4px 6px rgba(0, 0, 0, .6))'}>
      <Box
        transition="all 1s ease"
        bg={
          isScrolled
            ? 'linear-gradient(93deg, #2A4788, #3657a2, #2a4788)'
            : 'transparent'
        }
        clipPath={
          isScrolled && {
            xs: 'polygon(0 0, 100% 0, 100% 90%, 50% 100%, 50% 100%, 0 90%)',
            lg: 'polygon(0 0, 100% 0, 100% 84%, 50% 100%, 50% 100%, 0 84%)',
            xl: 'polygon(0 0, 100% 0, 100% 75%, 50% 100%, 50% 100%, 0 75%)',
          }
        }
        pb="1.5rem"
        zIndex="99">
        <Container maxW="8xl">
          <Stack flexDir="row" py="1rem">
            <Link href="/">
              <Box pointerEvents="all" cursor="pointer" as="a">
                <Image
                  h="4rem"
                  src={
                    isScrolled || props.detailedLogo
                      ? '/logo-comb-white.svg'
                      : props.colorizedLogo
                      ? '/logo-comb-color.svg'
                      : '/kompasfest-logo-white.svg'
                  }
                />
              </Box>
            </Link>

            {/* mobile nav */}
            <Box
              display={{ xs: 'flex', xl: 'none' }}
              flex={1}
              flexDir="row"
              alignItems="center"
              justifyContent="flex-end">
              <IconButton
                pointerEvents="all"
                fontSize="3rem"
                size="xl"
                aria-label="burger-menu"
                onClick={onOpen}
                icon={<IoReorderThreeOutline />}
              />
            </Box>

            <Drawer placement="right" isOpen={isOpen} onClose={onClose}>
              <DrawerOverlay />
              <DrawerContent>
                <DrawerHeader _after={{ display: 'none' }} pt="2rem">
                  <IconButton
                    float="right"
                    bg="transparent"
                    _hover={{ bg: 'transparent' }}
                    fontSize="3rem"
                    size="xl"
                    aria-label="close-menu"
                    onClick={onClose}
                    icon={<IoCloseOutline />}
                  />
                </DrawerHeader>
                <DrawerBody mt="-4rem">
                  <Stack spacing="1rem">
                    {subMenu}
                    <UserNavbarIndicator />
                  </Stack>
                </DrawerBody>
              </DrawerContent>
            </Drawer>
            {/* eof mobile nav */}

            {/* desktop nav */}
            <Stack
              display={{ xs: 'none', xl: 'flex' }}
              justify="flex-end"
              align="center"
              flex={1}
              direction="row"
              spacing="3rem">
              {subMenu}
              <UserNavbarIndicator />
            </Stack>
          </Stack>
        </Container>
      </Box>
    </Box>
  );
};

export default HomeNav;
