import { BoxProps } from '@chakra-ui/react';
import Speakers from '_components/HomeSpeakers';
import UniverseBackground from '_components/UniverseBackground';
import React from 'react';

const HomepageContent: React.FC<BoxProps> = (props) => {
  return (
    <UniverseBackground mt="-4rem" {...props}>
      <Speakers title="test" pb={{ md: '8rem', lg: '5rem', xl: '18rem' }} />
    </UniverseBackground>
  );
};

export default HomepageContent;
