import { Stack, StackProps, IconButton } from '@chakra-ui/react';
import React from 'react';
import { IoLogoFacebook, IoLogoInstagram } from 'react-icons/io5';

const SocialWidget: React.FC<StackProps> = (props) => (
  <Stack
    position="fixed"
    top={{ xs: '80%', lg: '50%' }}
    right="15px"
    transform="translateY(-50%)"
    zIndex="99"
    spacing="1rem"
    {...props}>
    <a href="https://www.facebook.com/kompasfest/" target="_blank">
      <IconButton
        fontSize="4xl"
        color={{ xs: 'gray.600', md: 'white' }}
        aria-label="facebook"
        icon={<IoLogoFacebook />}
      />
    </a>
    <a href="https://www.instagram.com/kompasfest_id" target="_blank">
      <IconButton
        fontSize="4xl"
        color={{ xs: 'gray.600', md: 'white' }}
        aria-label="instagram"
        icon={<IoLogoInstagram />}
      />
    </a>
  </Stack>
);

export default SocialWidget;
