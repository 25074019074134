import {
  Alert,
  ModalProps,
  Button,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import { useLoginModalStore } from '_store';
import React from 'react';
import { IoLogoFacebook, IoLogoGoogle } from 'react-icons/io5';

const LoginModal: React.FC<Partial<ModalProps>> = (props) => {
  const { isOpen, message, close } = useLoginModalStore();
  const handleLogin = (provider: string) => () => {
    window.location.href = `${process.env.NEXT_PUBLIC_API_URL}/connect/${provider}`;
  };

  return (
    <Modal {...props} isOpen={isOpen} onClose={close}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>LOGIN / REGISTER</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack>
            {message && <Alert status="error">{message}</Alert>}
            <Button
              onClick={handleLogin('facebook')}
              size="lg"
              leftIcon={<IoLogoFacebook />}>
              Login with Facebook
            </Button>
            <Button
              onClick={handleLogin('google')}
              size="lg"
              leftIcon={<IoLogoGoogle />}>
              Login with Google
            </Button>
          </Stack>
        </ModalBody>
        <ModalFooter />
      </ModalContent>
    </Modal>
  );
};

export default LoginModal;
