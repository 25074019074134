export const FREE_TIER = 0;
export const BORJOUIS_TIER = 1;
export const SULTAN_TIER = 2;
export const ADMIN_TIER = 3;

export const USER_TIERS = {
  [FREE_TIER]: 'Free Tier',
  [BORJOUIS_TIER]: 'Borjouis Tier',
  [SULTAN_TIER]: 'Sultan Tier',
  [ADMIN_TIER]: 'Admin Tier',
};
