import { Container, Stack, BoxProps, Box } from '@chakra-ui/react';
import Heading from '_components/DecoratedHeading';
import Speaker from '_components/Speaker';
import useSpeakersQuery from '_hooks/useSpeakersQuery';
import _chunk from 'lodash/chunk';
import React from 'react';
import SwiperCore, { Autoplay, Pagination, Navigation } from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/react';

export interface SpeakerProps extends BoxProps {
  title: string;
}

SwiperCore.use([Autoplay, Pagination, Navigation]);

const HomeSpeakers: React.FC<SpeakerProps> = () => {
  const { data } = useSpeakersQuery({});

  return (
    <Container maxW="container.xl" py="5rem" position="relative" zIndex="9">
      <Stack align="left" justify="top">
        <Stack spacing="24px">
          <Box
            my={{ xs: '2rem', md: '4rem', lg: '6rem' }}
            mb={{ xs: '2rem', md: '2rem', lg: '3rem' }}>
            <Heading
              withShadow
              color="white"
              fontSize={{ xs: '2rem', lg: '3rem' }}
              mt=".5rem">
              SPEAKERS
            </Heading>
          </Box>
          <Swiper
            pagination={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            breakpoints={{
              '320': {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              '768': {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              '1024': {
                slidesPerView: 4,
                spaceBetween: 20,
              },
              '1100': {
                slidesPerView: 5,
                spaceBetween: 20,
              },
              '1440': {
                slidesPerView: 6,
                spaceBetween: 32,
              },
            }}>
            {data?.map((speaker) => (
              <SwiperSlide key={speaker.id}>
                <Speaker
                  item={speaker}
                  speakerTitleColor="white"
                  presentAsRow
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </Stack>
      </Stack>
    </Container>
  );
};

export default HomeSpeakers;
