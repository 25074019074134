import { Stack, Text, StackProps } from '@chakra-ui/react';
import SocialWidget from '_components/SocialWidget';
import React from 'react';

const Footer: React.FC<StackProps> = (props) => (
  <Stack
    as="footer"
    textAlign="center"
    align="center"
    w="100%"
    justifyContent="flex-end"
    pb="1rem"
    {...props}>
    <SocialWidget
      position={{ xs: 'static', md: 'fixed' }}
      direction={{ xs: 'row', md: 'column' }}
      mb={{ xs: '-1rem', md: undefined }}
    />
    <Text color="gray.600">&copy;2021 by KOMPAS. All Rights Reserved</Text>
  </Stack>
);

export default Footer;
