import {
  Button,
  Avatar,
  Stack,
  Modal,
  ModalOverlay,
  useDisclosure,
  ModalContent,
  ModalBody,
  Heading,
  Box,
} from '@chakra-ui/react';
import LoginModal from '_components/LoginModal';
import RedeemForm from '_components/RedeemForm';
import { USER_TIERS } from '_constants/tiers';
import { useAccessControl } from '_hooks';
import { useUserLazyQuery } from '_hooks';
import { withFeatureToggle } from '_lib/featureFlag';
import { useLoginModalStore, useGenericModalStore } from '_store';
import { parseIdFromToken } from '_utils';
import Cookie from 'js-cookie';
import { useRouter } from 'next/router';
import React from 'react';
import { useMount } from 'react-use';

const UserNavbarIndicator: React.FC = () => {
  const router = useRouter();
  const [fetchUser, { loading, data }] = useUserLazyQuery();
  const { open: setOpenModal } = useGenericModalStore();
  const { open } = useLoginModalStore();
  const {
    isOpen: isOpenUserModal,
    onOpen: onOpenUserModal,
    onClose: onCloseUserModal,
  } = useDisclosure();

  const { userLevel } = useAccessControl(0);

  useMount(() => {
    try {
      const jwt = Cookie.get('accessToken');
      if (jwt) {
        const userId = parseIdFromToken(jwt);

        if (userId) {
          fetchUser({
            variables: {
              id: userId,
            },
          });
        }
      }
    } catch {}
  });

  const handleLogout = () => {
    router.replace('/').finally(() => {
      Cookie.remove('accessToken');
      window.location.reload();
    });
  };

  const handleOpenRedeemModal = () => {
    onCloseUserModal();

    setOpenModal({
      title: 'Redeem your ticket',
      content: <RedeemForm />,
    });
  };

  if (loading) {
    return null;
  }

  if (data?.user?.username) {
    return (
      <>
        <button onClick={onOpenUserModal} style={{ pointerEvents: 'all' }}>
          <Avatar size="sm" name={data?.user?.username} />
        </button>
        <Modal isOpen={isOpenUserModal} onClose={onCloseUserModal}>
          <Box position="relative" zIndex={99999999}>
            <ModalOverlay />
            <ModalContent>
              <ModalBody>
                <Stack py="1rem" direction="row" align="center">
                  <Avatar size="lg" name={data?.user?.username} />
                  <Stack pl=".5rem">
                    <Heading size="md">{data?.user?.username}</Heading>
                    <Heading color="gray.400" size="xs">
                      {USER_TIERS[userLevel] || 'Free Tier'}
                    </Heading>
                  </Stack>
                </Stack>
                <Stack spacing="1rem" pb="1rem">
                  <Button onClick={handleOpenRedeemModal}>Redeem Ticket</Button>
                  <Button>Settings</Button>
                  <Button onClick={handleLogout}>Logout</Button>
                </Stack>
              </ModalBody>
            </ModalContent>
          </Box>
        </Modal>
      </>
    );
  }

  return (
    <>
      <LoginModal />
      <Button pointerEvents="all" onClick={open}>
        LOGIN / REGISTER
      </Button>
    </>
  );
};

const NullComponent = () => null;

export default withFeatureToggle('login-modal')(NullComponent)(
  UserNavbarIndicator,
);
