import { Box } from '@chakra-ui/react';
import Container from '_components/DecoratedContainer';
import FixedSponsors from '_components/FixedSponsors';
import Footer from '_components/Footer';
import HomeHeader from '_components/HomeHeader';
import HomeLayout from '_components/HomeLayout';
import HomepageContent from '_components/HomepageContent';
import { NextPage } from 'next';
import { ClientSafeProvider } from 'next-auth/client';
import { NextSeo } from 'next-seo';
import React from 'react';

interface HomeProps {
  providers: ClientSafeProvider;
}

const Home: NextPage<HomeProps> = () => {
  return (
    <HomeLayout>
      <NextSeo
        title="Kompas Fest Navigate | WE NAVIGATE YOUR PASSION FOR A BRIGHTER FUTURE"
        description="WE NAVIGATE YOUR PASSION FOR A BRIGHTER FUTURE"
      />
      <HomeHeader />
      <HomepageContent pb={{ md: '8rem', lg: '5rem', xl: '18rem' }} />
      <Box
        pt={{ xs: '20rem', sm: '14rem', md: '8rem', lg: '10rem', xl: '20rem' }}
        bgImage="url(https://res.cloudinary.com/dmf6v1knt/image/upload/v1629085137/moon_dnpzu9_c22pot.png)"
        bgSize="cover"
        bgRepeat="no-repeat"
        bgPosition="center top"
        mt={{
          xs: '-5rem',
          sm: '-5rem',
          md: '-7rem',
          lg: '-10rem',
          xl: '-18rem',
        }}
        position="relative">
        <Container>
          <FixedSponsors />
          <Footer mt="3rem" />
        </Container>
      </Box>
    </HomeLayout>
  );
};

export default Home;
