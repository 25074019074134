import { ResponsiveImage } from '@adeyahya/rrp';
import { Box, Stack, Text, Image, BoxProps } from '@chakra-ui/react';
import Container from '_components/DecoratedContainer';
import { withFeatureToggle } from '_lib/featureFlag';
import Link from 'next/link';
import React from 'react';

const NullComponent = () => null;

const ExploreNow = () => {
  return (
    <Link href="/intro">
      <Box
        position="absolute"
        top="50%"
        cursor="pointer"
        display={{ xs: 'none', lg: 'block' }}
        right={{ sm: '10%', lg: '50px' }}
        transform="translateY(-50%)">
        <ResponsiveImage src="https://res.cloudinary.com/kompasfest/image/upload/v1628748539/explore-now_xja7uu.png">
          <Image
            h="70px"
            maxW="20rem"
            _hover={{
              h: '75px',
            }}
            objectFit="contain"
            objectPosition="center center"
          />
        </ResponsiveImage>
      </Box>
    </Link>
  );
};

const HomeHeader: React.FC<BoxProps> = (props) => {
  const ToggledExploreNow =
    withFeatureToggle('explore-now')(NullComponent)(ExploreNow);

  return (
    <Box
      display="flex"
      position="relative"
      minH="100vh"
      pb={{ xs: '6rem', lg: '8rem' }}
      filter="drop-shadow(2px 4px 6px rgba(0, 0, 0, .6))"
      zIndex="2"
      {...props}>
      <picture>
        <source
          srcSet="https://res.cloudinary.com/kompasfest/image/upload/c_scale,w_1024/v1622381718/home-banner_i6yotk.jpg 1024w, https://res.cloudinary.com/kompasfest/image/upload/c_scale,w_1536/v1622381718/home-banner_i6yotk.jpg 1.5x"
          media="(min-width: 1024px)"
        />
        <source
          srcSet="https://res.cloudinary.com/kompasfest/image/upload/c_scale,w_768/v1622381716/home-banner-potrait_gn2e8u.jpg 768w, https://res.cloudinary.com/kompasfest/image/upload/c_scale,w_1152/v1622381716/home-banner-potrait_gn2e8u.jpg 1.5x"
          media="(min-width: 768px)"
        />
        <source
          srcSet="https://res.cloudinary.com/kompasfest/image/upload/c_scale,w_320/v1622381716/home-banner-potrait_gn2e8u.jpg 320w, https://res.cloudinary.com/kompasfest/image/upload/c_scale,w_480/v1622381716/home-banner-potrait_gn2e8u.jpg 1.5x"
          media="(min-width: 320px)"
        />
        <Image
          position="absolute"
          w="100%"
          h="100%"
          top="0"
          left="0"
          clipPath={{
            xs: 'polygon(0 0, 100% 0, 100% 97%, 50% 100%, 50% 100%, 0 97%)',
            lg: 'polygon(0 0, 100% 0, 100% 93%, 50% 100%, 50% 100%, 0 93%)',
          }}
          objectFit="cover"
          zIndex="1"
          src="https://res.cloudinary.com/kompasfest/image/upload/c_scale,w_480/v1622381716/home-banner-potrait_gn2e8u.jpg"
        />
      </picture>
      <Container flex={1} zIndex="2" position="relative">
        <Stack
          h="100%"
          flexDir="column"
          justify={{ xs: 'flex-start', md: 'space-between' }}>
          <Box
            mt={{ xs: '22rem', md: '20rem' }}
            mb={{ xs: '.5rem', md: '4rem' }}>
            <Image
              w={{ xs: '260px', md: 'auto' }}
              maxW="550px"
              src="/logo-text-white.svg"
            />
            <Text
              textTransform="uppercase"
              fontWeight="bold"
              letterSpacing=".165rem"
              textShadow={{ xs: '2px 2px 6px rgba(0, 0, 0, .7)', md: 'none' }}
              fontSize={{ xs: '.9rem', md: '1.2rem' }}
              mt="1rem">
              We navigate your passion for a brighter future
            </Text>
          </Box>
          <Stack
            align={{ lg: 'center' }}
            direction={{
              lg: 'row',
              xs: 'column-reverse',
            }}
            spacing={{ xs: 4, lg: 6 }}>
            <Box
              fontSize="2rem"
              textAlign={{ lg: 'right' }}
              color="bluesky.400"
              minW="180px">
              <Text letterSpacing="1px" fontWeight="500">
                20<sup>th</sup> - 21<sup>st</sup>
              </Text>
              <Text
                letterSpacing="2px"
                textTransform="uppercase"
                fontWeight="bold">
                Aug 2021
              </Text>
            </Box>
            <Box
              as="span"
              alignSelf="stretch"
              w={{ xs: '100px', lg: '2px' }}
              h={{ xs: '2px', lg: '100%' }}
              bg="gray.400"
            />
            <Stack spacing="0.5rem" pr={{ lg: '1rem' }}>
              <Text
                fontSize={{ xs: '15px', lg: '13px' }}
                fontWeight="500"
                textShadow="2px 2px 6px rgba(0, 0, 0, .7)">
                Kompasfest, festival dengan format workshop yang hadir berangkat
                dari pertanyaan-pertanyaan generasi muda.
              </Text>
              <Text
                fontSize={{ xs: '15px', lg: '13px' }}
                fontWeight="500"
                textShadow="2px 2px 6px rgba(0, 0, 0, .7)">
                Kompasfest menawarkan pemahaman yang lebih mendalam mengenai
                topik-topik yang berbasis hasil dan peningkatan keterampilan.
              </Text>
              <Text
                fontSize={{ xs: '15px', lg: '13px' }}
                fontWeight="500"
                textShadow="2px 2px 6px rgba(0, 0, 0, .7)">
                Berkolaborasi dengan para pembicara yang penuh wawasan dan
                inspiratif, Kompasfest mendorong generasi muda untuk MULAI DAN
                BERAKSI.
              </Text>
            </Stack>
          </Stack>
        </Stack>

        <ToggledExploreNow />
      </Container>
    </Box>
  );
};

export default HomeHeader;
