import { ResponsiveImage } from '@adeyahya/rrp';
import { Box, Image, BoxProps } from '@chakra-ui/react';
import StarsBackground from '_components/StarsBackground';
import React from 'react';

const UniverseBackground: React.FC<BoxProps> = ({ children, ...props }) => {
  return (
    <Box position="relative" zIndex="0" pb={{ lg: '5rem' }} {...props}>
      <StarsBackground />
      <ResponsiveImage src="https://res.cloudinary.com/dt2mntbmf/image/upload/v1621933640/bg-top-overlay_tojp5h.png">
        <Image
          pointerEvents="none"
          position="absolute"
          top="0"
          left="0"
          w="100%"
          zIndex="0"
          mixBlendMode="lighten"
          objectFit="cover"
        />
      </ResponsiveImage>
      <ResponsiveImage src="https://res.cloudinary.com/dt2mntbmf/image/upload/v1621943763/planet-dark_heael8.png">
        <Image
          pointerEvents="none"
          position="absolute"
          top="5rem"
          zIndex="0"
          right={{ xs: '-3rem', lg: '-7rem' }}
          w={{ xs: '40vw', sm: '30vw', lg: '400px' }}
        />
      </ResponsiveImage>
      <ResponsiveImage src="https://res.cloudinary.com/dt2mntbmf/image/upload/v1621943763/planet-dark_heael8.png">
        <Image
          pointerEvents="none"
          position="absolute"
          bottom={{ xs: '-2rem', sm: '-5rem', lg: '-15rem' }}
          transform="scaleX(-1)"
          zIndex="0"
          left={{ xs: '-5rem', md: '-10rem', lg: '-14rem' }}
          w={{ xs: '70vw', sm: '60vw', lg: '700px' }}
        />
      </ResponsiveImage>
      <ResponsiveImage src="https://res.cloudinary.com/dt2mntbmf/image/upload/v1621943144/satellite_txo5cq.png">
        <Image
          pointerEvents="none"
          position="absolute"
          bottom={{
            xs: '3rem',
            sm: '2rem',
            md: '3.5rem',
            lg: '6rem',
            xl: '6rem',
          }}
          zIndex="2"
          right="0"
          w={{ xs: '50vw', md: '400px' }}
        />
      </ResponsiveImage>
      {children}
    </Box>
  );
};

export default UniverseBackground;
