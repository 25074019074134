import { ResponsiveImage } from '@adeyahya/rrp';
import { Box, Image, Wrap, WrapItem, Link } from '@chakra-ui/react';
import Heading from '_components/DecoratedHeading';
import React from 'react';

const FixedSponsor = () => {
  return (
    <>
      {['desktop', 'mobile'].map((n) => (
        <Wrap
          key={n}
          position="relative"
          zIndex="1"
          direction="row"
          align="flex-start"
          mt={{ md: '5rem', xl: '0.5rem' }}
          justify="center"
          pt={n === 'mobile' ? '3rem' : 'auto'}
          spacing={n === 'mobile' ? '5rem' : '10rem'}
          display={
            n === 'mobile'
              ? { xs: 'block', lg: 'none' }
              : { xs: 'none', lg: 'block' }
          }>
          <WrapItem display="flex" justifyContent={{ lg: 'center' }}>
            <Box>
              <Heading
                color="blue.500"
                textAlign="center"
                display="flex"
                justifyContent="center"
                fontSize={{ xs: 'lg', lg: 'lg' }}
                useBottomSeparator={false}>
                Host
              </Heading>

              <Link
                href="https://www.kompas.id/?rel=kompasfest-navigate"
                isExternal>
                <ResponsiveImage src="https://res.cloudinary.com/dt2mntbmf/image/upload/v1621946111/kompas_x34f51.png">
                  <Image
                    h="70px"
                    maxW="150px"
                    objectFit="contain"
                    objectPosition="center center"
                  />
                </ResponsiveImage>
              </Link>
            </Box>
          </WrapItem>
          <WrapItem display="flex" justifyContent={{ lg: 'center' }}>
            <Box>
              <Heading
                color="blue.500"
                textAlign="center"
                fontSize={{ xs: 'lg', lg: 'lg' }}
                mb="0.5rem"
                display="flex"
                justifyContent="center"
                useBottomSeparator={false}>
                co-host
              </Heading>

              <ResponsiveImage src="https://res.cloudinary.com/dt2mntbmf/image/upload/v1621946111/idelaju_cipptn.png">
                <Image
                  h="50px"
                  maxW="150px"
                  objectFit="contain"
                  objectPosition="center center"
                />
              </ResponsiveImage>
            </Box>
          </WrapItem>
          <WrapItem display="flex" justifyContent={{ lg: 'center' }}>
            <Box>
              <Heading
                color="blue.500"
                textAlign="center"
                display="flex"
                justifyContent="center"
                fontSize={{ xs: 'lg', lg: 'lg' }}
                mb="0.5rem"
                useBottomSeparator={false}>
                Main Sponsor
              </Heading>

              <Wrap
                align="flex-start"
                justify="center"
                direction="row"
                spacing="2rem">
                <WrapItem display="flex" justifyContent={{ lg: 'center' }}>
                  <Link
                    href="https://www.astra.co.id/?rel=kompasfest-navigate"
                    isExternal>
                    <ResponsiveImage src="https://res.cloudinary.com/dt2mntbmf/image/upload/v1621946105/astra_pnoovo.png">
                      <Image
                        h="60px"
                        maxW="180px"
                        objectFit="contain"
                        objectPosition="center center"
                      />
                    </ResponsiveImage>
                  </Link>
                </WrapItem>
                <WrapItem display="flex" justifyContent={{ lg: 'center' }}>
                  <Link
                    href="https://east.vc/?rel=kompasfest-navigate"
                    isExternal>
                    <ResponsiveImage src="https://res.cloudinary.com/dt2mntbmf/image/upload/v1625569773/east-ventures_zzwpae.png">
                      <Image
                        h="72px"
                        maxW="240"
                        objectFit="contain"
                        objectPosition="center center"
                      />
                    </ResponsiveImage>
                  </Link>
                </WrapItem>
              </Wrap>
            </Box>
          </WrapItem>
        </Wrap>
      ))}
      <Wrap align="center" justify="center" mt="2.5rem" spacing="4rem">
        <WrapItem display="flex" justifyContent={{ lg: 'center' }}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Heading
              color="blue.500"
              textAlign="center"
              display="flex"
              justifyContent="center"
              fontSize={{ xs: 'lg', lg: 'lg' }}
              mb="0.1rem"
              useBottomSeparator={false}>
              Co-Sponsor
            </Heading>
            <Wrap
              align="flex-start"
              justify="center"
              direction="row"
              spacing="2rem">
              <WrapItem display="flex" justifyContent={{ lg: 'center' }}>
                <Link
                  href="https://www.aia-financial.co.id/id/index.html?rel=kompasfest-navigate"
                  isExternal>
                  <ResponsiveImage src="https://res.cloudinary.com/dt2mntbmf/image/upload/v1625570959/aia_ownkbk.png">
                    <Image
                      h="60px"
                      maxW={{ xs: '115px', lg: '130px' }}
                      objectFit="contain"
                      objectPosition="center center"
                    />
                  </ResponsiveImage>
                </Link>
              </WrapItem>
              <WrapItem display="flex" justifyContent={{ lg: 'center' }}>
                <Link
                  href="https://www.rgei.com/?rel=kompasfest-navigate"
                  isExternal>
                  <ResponsiveImage src="https://res.cloudinary.com/kompasfest/image/upload/v1626799883/RGE_xhokru.png">
                    <Image
                      h="50px"
                      mt="5px"
                      maxW={{ xs: '45px', lg: '70px' }}
                      objectFit="contain"
                      objectPosition="center center"
                    />
                  </ResponsiveImage>
                </Link>
              </WrapItem>
              <WrapItem display="flex" justifyContent={{ lg: 'center' }}>
                <Link
                  href="https://www.tanotofoundation.org/id/?rel=kompasfest-navigate"
                  isExternal>
                  <ResponsiveImage src="https://res.cloudinary.com/kompasfest/image/upload/v1626925909/tanoto_m5xr0l.png">
                    <Image
                      h="60px"
                      maxW={{ xs: '75px', lg: '90px' }}
                      objectFit="contain"
                      objectPosition="center center"
                    />
                  </ResponsiveImage>
                </Link>
              </WrapItem>
              <WrapItem display="flex" justifyContent={{ lg: 'center' }}>
                <Link
                  href="http://www.aprayon.com/?rel=kompasfest-navigate"
                  isExternal>
                  <ResponsiveImage src="https://res.cloudinary.com/kompasfest/image/upload/v1626799883/APR_kbqtz5.png">
                    <Image
                      h="60px"
                      maxW={{ xs: '55px', lg: '70px' }}
                      objectFit="contain"
                      objectPosition="center center"
                    />
                  </ResponsiveImage>
                </Link>
              </WrapItem>
              <WrapItem display="flex" justifyContent={{ lg: 'center' }}>
                <Link
                  href="https://www.aprilasia.com/en/?rel=kompasfest-navigate"
                  isExternal>
                  <ResponsiveImage src="https://res.cloudinary.com/kompasfest/image/upload/v1626799883/APRIL_shw2yr.png">
                    <Image
                      h="50px"
                      maxW={{ xs: '85px', lg: '100px' }}
                      objectFit="contain"
                      objectPosition="center center"
                    />
                  </ResponsiveImage>
                </Link>
              </WrapItem>
            </Wrap>
          </Box>
        </WrapItem>
        <WrapItem display="flex" justifyContent={{ lg: 'center' }} space="2rem">
          <Box display="flex" flexDirection="column" alignItems="center">
            <Heading
              color="blue.500"
              textAlign="center"
              fontSize={{ xs: 'lg', lg: 'lg' }}
              mb="0.1rem"
              mx="1rem"
              display="flex"
              justifyContent="center"
              useBottomSeparator={false}>
              Ticketing Partner
            </Heading>
            <Link
              href="https://www.tokopedia.com/?rel=kompasfest-navigate"
              isExternal>
              <ResponsiveImage src="https://res.cloudinary.com/dt2mntbmf/image/upload/v1625570877/tokopedia_oxqvje.png">
                <Image
                  h="60px"
                  maxW={{ xs: '120px', lg: '130px' }}
                  objectFit="contain"
                  objectPosition="center center"
                />
              </ResponsiveImage>
            </Link>
          </Box>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Heading
              color="blue.500"
              textAlign="center"
              display="flex"
              justifyContent="center"
              fontSize={{ xs: 'lg', lg: 'lg' }}
              mb="0.1rem"
              mx="1rem"
              useBottomSeparator={false}>
              Venue Partner
            </Heading>
            <Link
              href="https://www.umn.ac.id/en/home/?rel=kompasfest-navigate"
              isExternal>
              <ResponsiveImage src="https://res.cloudinary.com/kompasfest/image/upload/v1628782984/LOGO_j3rnxy.png">
                <Image
                  h="60px"
                  maxW={{ xs: '120px', lg: '130px' }}
                  objectFit="contain"
                  objectPosition="center center"
                />
              </ResponsiveImage>
            </Link>
          </Box>
        </WrapItem>
      </Wrap>

      {/* MEDIA PARTNER */}

      <Wrap align="center" justify="center" mt="2.5rem" spacing="4rem">
        <WrapItem display="flex" justifyContent={{ lg: 'center' }}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Heading
              color="blue.500"
              textAlign="center"
              display="block"
              fontSize={{ xs: 'lg', lg: 'lg' }}
              mb="0.1rem"
              useBottomSeparator={false}>
              Media Partner
            </Heading>
            <Wrap
              align="flex-start"
              justify="center"
              direction="row"
              mt="20px"
              maxWidth={{ xs: '95%', lg: '100%', xl: '100%' }}
              spacing="2rem">
              <WrapItem display="flex" justifyContent={{ lg: 'center' }}>
                <Link href="#" isExternal>
                  <ResponsiveImage src="https://res.cloudinary.com/kompasfest/image/upload/v1627100788/1_KOMPASGRAMEDIA_sxkj5w.png">
                    <Image
                      h="40px"
                      mt="12px"
                      maxW={{ xs: '30px', lg: '70px' }}
                      objectFit="contain"
                      objectPosition="center center"
                    />
                  </ResponsiveImage>
                </Link>
              </WrapItem>
              <WrapItem display="flex" justifyContent={{ lg: 'center' }}>
                <Link href="#" isExternal>
                  <ResponsiveImage src="https://res.cloudinary.com/kompasfest/image/upload/v1627100788/2_MYKG_sdw1zn.png">
                    <Image
                      h="50px"
                      mt="5px"
                      maxW={{ xs: '60px', lg: '90px' }}
                      objectFit="contain"
                      objectPosition="center center"
                    />
                  </ResponsiveImage>
                </Link>
              </WrapItem>
              <WrapItem display="flex" justifyContent={{ lg: 'center' }}>
                <Link href="#" isExternal>
                  <ResponsiveImage src="https://res.cloudinary.com/kompasfest/image/upload/v1627100867/3_KOMPASTV_1_s7vemf.png">
                    <Image
                      h="60px"
                      maxW={{ xs: '75px', lg: '110px' }}
                      objectFit="contain"
                      objectPosition="center center"
                    />
                  </ResponsiveImage>
                </Link>
              </WrapItem>
              <WrapItem display="flex" justifyContent={{ lg: 'center' }}>
                <Link href="#" isExternal>
                  <ResponsiveImage src="https://res.cloudinary.com/kompasfest/image/upload/v1627100779/logokompas_eox6q4.png">
                    <Image
                      h="60px"
                      maxW={{ xs: '85px', lg: '130px' }}
                      objectFit="contain"
                      objectPosition="center center"
                    />
                  </ResponsiveImage>
                </Link>
              </WrapItem>
              <WrapItem display="flex" justifyContent={{ lg: 'center' }}>
                <Link href="#" isExternal>
                  <ResponsiveImage src="https://res.cloudinary.com/kompasfest/image/upload/v1627396431/1._Logo_Kompasiana_Biru_o1vgd3.png">
                    <Image
                      h="60px"
                      maxW={{ xs: '85px', lg: '100px' }}
                      objectFit="contain"
                      objectPosition="center center"
                    />
                  </ResponsiveImage>
                </Link>
              </WrapItem>
              <WrapItem display="flex" justifyContent={{ lg: 'center' }}>
                <Link href="#" isExternal>
                  <ResponsiveImage src="https://res.cloudinary.com/kompasfest/image/upload/v1627492831/Logo_Kontan_2016_Original_vqfzeg.png">
                    <Image
                      h="60px"
                      maxW={{ xs: '95px', lg: '110px' }}
                      objectFit="contain"
                      objectPosition="center center"
                    />
                  </ResponsiveImage>
                </Link>
              </WrapItem>
              <WrapItem display="flex" justifyContent={{ lg: 'center' }}>
                <Link href="#" isExternal>
                  <ResponsiveImage src="https://res.cloudinary.com/kompasfest/image/upload/v1627396438/2._Logo_KC_UMN_2_dtket2.png">
                    <Image
                      h="40px"
                      maxW={{ xs: '65px', lg: '70px' }}
                      objectFit="contain"
                      objectPosition="center center"
                    />
                  </ResponsiveImage>
                </Link>
              </WrapItem>
              <WrapItem display="flex" justifyContent={{ lg: 'center' }}>
                <Link href="#" isExternal>
                  <ResponsiveImage src="https://res.cloudinary.com/kompasfest/image/upload/v1627396430/3._Logo_KC_UWM_w144x6.png">
                    <Image
                      h="55px"
                      maxW={{ xs: '95px', lg: '100px' }}
                      objectFit="contain"
                      objectPosition="center center"
                    />
                  </ResponsiveImage>
                </Link>
              </WrapItem>
              <WrapItem display="flex" justifyContent={{ lg: 'center' }}>
                <Link href="#" isExternal>
                  <ResponsiveImage src="https://res.cloudinary.com/kompasfest/image/upload/v1627102053/1_TRIBUNNEWS_jwy8xd.png">
                    <Image
                      h="60px"
                      maxW={{ xs: '115px', lg: '130px' }}
                      objectFit="contain"
                      objectPosition="center center"
                    />
                  </ResponsiveImage>
                </Link>
              </WrapItem>
              <WrapItem display="flex" justifyContent={{ lg: 'center' }}>
                <Link href="#" isExternal>
                  <ResponsiveImage src="https://res.cloudinary.com/kompasfest/image/upload/v1627102053/2_USS_Feed_jtkvza.png">
                    <Image
                      h="50px"
                      mt="5px"
                      maxW={{ xs: '65px', lg: '100px' }}
                      objectFit="contain"
                      objectPosition="center center"
                    />
                  </ResponsiveImage>
                </Link>
              </WrapItem>
              <WrapItem display="flex" justifyContent={{ lg: 'center' }}>
                <Link href="#" isExternal>
                  <ResponsiveImage src="https://res.cloudinary.com/kompasfest/image/upload/v1627101526/1_MOTION_FM_2_vuln8j.png">
                    <Image
                      mt={{ xs: '10px' }}
                      h={{ xs: '40px', lg: '55px' }}
                      maxW={{ xs: '50px', lg: '80px' }}
                      objectFit="contain"
                      objectPosition="center center"
                    />
                  </ResponsiveImage>
                </Link>
              </WrapItem>
              <WrapItem display="flex" justifyContent={{ lg: 'center' }}>
                <Link href="#" isExternal>
                  <ResponsiveImage src="https://res.cloudinary.com/kompasfest/image/upload/v1627101537/2_SMART_FM_1_ikceaq.png">
                    <Image
                      h="50px"
                      mt="5px"
                      maxW={{ xs: '70px', lg: '100px' }}
                      objectFit="contain"
                      objectPosition="center center"
                    />
                  </ResponsiveImage>
                </Link>
              </WrapItem>
              <WrapItem display="flex" justifyContent={{ lg: 'center' }}>
                <Link href="#" isExternal>
                  <ResponsiveImage src="https://res.cloudinary.com/kompasfest/image/upload/v1627101269/3_Hard_Rock_FM_ynrgno.png">
                    <Image
                      h="60px"
                      maxW={{ xs: '75px', lg: '90px' }}
                      objectFit="contain"
                      objectPosition="center center"
                    />
                  </ResponsiveImage>
                </Link>
              </WrapItem>
              <WrapItem display="flex" justifyContent={{ lg: 'center' }}>
                <Link href="#" isExternal>
                  <ResponsiveImage src="https://res.cloudinary.com/kompasfest/image/upload/v1627101269/4_TRAXFM_JKT_TAGLINE_mzhm9z.png">
                    <Image
                      h="60px"
                      maxW={{ xs: '70px', lg: '90px' }}
                      objectFit="contain"
                      objectPosition="center center"
                    />
                  </ResponsiveImage>
                </Link>
              </WrapItem>
              <WrapItem display="flex" justifyContent={{ lg: 'center' }}>
                <Link href="#" isExternal>
                  <ResponsiveImage src="https://res.cloudinary.com/kompasfest/image/upload/v1627102052/3_Cosmopolitan_Indonesia_vgolvc.png">
                    <Image
                      h="60px"
                      maxW={{ xs: '85px', lg: '110px' }}
                      objectFit="contain"
                      objectPosition="center center"
                    />
                  </ResponsiveImage>
                </Link>
              </WrapItem>
              <WrapItem display="flex" justifyContent={{ lg: 'center' }}>
                <Link href="#" isExternal>
                  <ResponsiveImage src="https://res.cloudinary.com/kompasfest/image/upload/v1627102053/4_gridgames_wwy4zz.png">
                    <Image
                      h="80px"
                      mt="-12px"
                      maxW={{ xs: '65px', lg: '130px' }}
                      objectFit="contain"
                      objectPosition="center center"
                    />
                  </ResponsiveImage>
                </Link>
              </WrapItem>
              <WrapItem display="flex" justifyContent={{ lg: 'center' }}>
                <Link href="#" isExternal>
                  <ResponsiveImage src="https://res.cloudinary.com/kompasfest/image/upload/v1627102053/5_BOLASPORT-01_qfsjt4.png">
                    <Image
                      h="55px"
                      maxW={{ xs: '95px', lg: '100px' }}
                      objectFit="contain"
                      objectPosition="center center"
                    />
                  </ResponsiveImage>
                </Link>
              </WrapItem>
              <WrapItem display="flex" justifyContent={{ lg: 'center' }}>
                <Link href="#" isExternal>
                  <ResponsiveImage src="https://res.cloudinary.com/kompasfest/image/upload/v1627102052/6_HAI_PUTIH-01_hxrv8e.png">
                    <Image
                      h="50px"
                      mt="5px"
                      maxW={{ xs: '90px', lg: '120px' }}
                      objectFit="contain"
                      objectPosition="center center"
                    />
                  </ResponsiveImage>
                </Link>
              </WrapItem>
              <WrapItem display="flex" justifyContent={{ lg: 'center' }}>
                <Link href="#" isExternal>
                  <ResponsiveImage src="https://res.cloudinary.com/kompasfest/image/upload/v1627102052/7_intisari_uk5rqk.png">
                    <Image
                      h="60px"
                      maxW={{ xs: '55px', lg: '70px' }}
                      objectFit="contain"
                      objectPosition="center center"
                    />
                  </ResponsiveImage>
                </Link>
              </WrapItem>
              <WrapItem display="flex" justifyContent={{ lg: 'center' }}>
                <Link href="#" isExternal>
                  <ResponsiveImage src="https://res.cloudinary.com/kompasfest/image/upload/v1627102052/8_NEXTREN-01_pmdx6m.png">
                    <Image
                      h="60px"
                      maxW={{ xs: '95px', lg: '110px' }}
                      objectFit="contain"
                      objectPosition="center center"
                    />
                  </ResponsiveImage>
                </Link>
              </WrapItem>
              <WrapItem display="flex" justifyContent={{ lg: 'center' }}>
                <Link href="#" isExternal>
                  <ResponsiveImage src="https://res.cloudinary.com/kompasfest/image/upload/v1627396436/4._Logo_Utama_-_logo_stylo_berwarna_xeu0zy.png">
                    <Image
                      h="35px"
                      mt="10px"
                      maxW={{ xs: '90px', lg: '90px' }}
                      objectFit="contain"
                      objectPosition="center center"
                    />
                  </ResponsiveImage>
                </Link>
              </WrapItem>
              <WrapItem display="flex" justifyContent={{ lg: 'center' }}>
                <Link href="#" isExternal>
                  <ResponsiveImage src="https://res.cloudinary.com/kompasfest/image/upload/v1627396425/5._LOGO__CEWEKBANGET_fiycdi.png">
                    <Image
                      h="60px"
                      maxW={{ xs: '55px', lg: '100px' }}
                      objectFit="contain"
                      objectPosition="center center"
                    />
                  </ResponsiveImage>
                </Link>
              </WrapItem>
              <WrapItem display="flex" justifyContent={{ lg: 'center' }}>
                <Link href="#" isExternal>
                  <ResponsiveImage src="https://res.cloudinary.com/kompasfest/image/upload/v1627396426/6._LOGO__GRID.ID_qkonf9.png">
                    <Image
                      h="50px"
                      maxW={{ xs: '85px', lg: '190px' }}
                      objectFit="contain"
                      objectPosition="center center"
                    />
                  </ResponsiveImage>
                </Link>
              </WrapItem>
              <WrapItem display="flex" justifyContent={{ lg: 'center' }}>
                <Link href="#" isExternal>
                  <ResponsiveImage src="https://res.cloudinary.com/kompasfest/image/upload/v1627396427/7._Logo_GridOto.com_3x_urc7al.png">
                    <Image
                      h="50px"
                      mt="5px"
                      maxW={{ xs: '65px', lg: '100px' }}
                      objectFit="contain"
                      objectPosition="center center"
                    />
                  </ResponsiveImage>
                </Link>
              </WrapItem>
              <WrapItem display="flex" justifyContent={{ lg: 'center' }}>
                <Link href="#" isExternal>
                  <ResponsiveImage src="https://res.cloudinary.com/kompasfest/image/upload/v1627396427/8._Logo_Motorplus_Online_3x_vkqjfv.png">
                    <Image
                      h="60px"
                      maxW={{ xs: '60px', lg: '85px' }}
                      objectFit="contain"
                      objectPosition="center center"
                    />
                  </ResponsiveImage>
                </Link>
              </WrapItem>
              <WrapItem display="flex" justifyContent={{ lg: 'center' }}>
                <Link href="#" isExternal>
                  <ResponsiveImage src="https://res.cloudinary.com/kompasfest/image/upload/v1627396424/9._Logo_Otomotif_3x_tbwjum.png">
                    <Image
                      h="60px"
                      maxW={{ xs: '75px', lg: '90px' }}
                      objectFit="contain"
                      objectPosition="center center"
                    />
                  </ResponsiveImage>
                </Link>
              </WrapItem>
              <WrapItem display="flex" justifyContent={{ lg: 'center' }}>
                <Link href="#" isExternal>
                  <ResponsiveImage src="https://res.cloudinary.com/kompasfest/image/upload/v1627493299/DS_NEW__main_logo_color_u1mxeu.png">
                    <Image
                      h="55px"
                      maxW={{ xs: '75px', lg: '90px' }}
                      objectFit="contain"
                      objectPosition="center center"
                    />
                  </ResponsiveImage>
                </Link>
              </WrapItem>
              <WrapItem display="flex" justifyContent={{ lg: 'center' }}>
                <Link href="#" isExternal>
                  <ResponsiveImage src="https://res.cloudinary.com/kompasfest/image/upload/v1627493299/logo_SEA_TODAY_BLACK_jcujrm.png">
                    <Image
                      h="65px"
                      maxW={{ xs: '60px', lg: '75px' }}
                      objectFit="contain"
                      objectPosition="center center"
                    />
                  </ResponsiveImage>
                </Link>
              </WrapItem>
              <WrapItem display="flex" justifyContent={{ lg: 'center' }}>
                <Link href="#" isExternal>
                  <ResponsiveImage src="https://res.cloudinary.com/kompasfest/image/upload/v1627493300/LOGO_PRAMBORS_NASIONAL_-_BLACK_fmdclb.png">
                    <Image
                      h="60px"
                      maxW={{ xs: '75px', lg: '90px' }}
                      objectFit="contain"
                      objectPosition="center center"
                    />
                  </ResponsiveImage>
                </Link>
              </WrapItem>
            </Wrap>
          </Box>
        </WrapItem>
      </Wrap>
    </>
  );
};

export default FixedSponsor;
