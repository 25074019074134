import { Box } from '@chakra-ui/react';
import HomeNav from '_components/HomeNav';
import { NAVIGATION_MENUS } from '_constants/navigation';
import { useAccessControl } from '_hooks';
import { FeatureFlagContext } from '_lib/featureFlag';
import React from 'react';

export interface HomeLayoutProps {
  colorizedLogo?: boolean;
  detailedLogo?: boolean;
  static?: boolean;
}

const HomeLayout: React.FC<HomeLayoutProps> = (props) => {
  const ctx = React.useContext(FeatureFlagContext) ?? {};
  const { userLevel } = useAccessControl();

  return (
    <Box position="relative" overflow="hidden">
      <HomeNav
        colorizedLogo={props.colorizedLogo}
        detailedLogo={props.detailedLogo}
        static={props.static}
        menus={NAVIGATION_MENUS(ctx).filter((nav) => {
          if (userLevel === -1 && nav.title === 'Explore Now') {
            return false;
          } else {
            return true;
          }
        })}
      />
      {props.children}
    </Box>
  );
};

export default HomeLayout;
